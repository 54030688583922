// RadioInput.js
import React from "react";

const RadioInput = ({
  label,
  boldLabel,
  discription,
  value,
  checked,
  onChange,
  colorChecked
}) => {
  return (
    <label
      className={`${
        colorChecked ? "bg-[#2d3d23] text-white" : "hover:bg-slate-200"
      } text-left  py-2 lg:py-4 px-5 rounded border flex items-center`}
    >
      <input
        type="radio"
        name="value"
        value={value}
        checked={checked}
        onChange={onChange}
        className="hidden"
        // autoFocus=true
      />
      <div className="flex flex-col capitalize">
        {label}
        <span className="font-semibold">{boldLabel}</span>
        <span
          className={`${
            checked ? "text-white" : "text-zinc-500"
          } text-xs md:text-sm `}
          style={{ wordBreak: "break-word" }}
        >
          {discription}
        </span>
      </div>
    </label>
  );
};

export default RadioInput;
